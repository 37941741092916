import {Component} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatDialogActions, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {AuthService} from "../../../services/auth.service";
import {addDoc, collection, Firestore} from "@angular/fire/firestore";
import {ModalHeaderComponent} from '../../../layout/modal-header/modal-header.component';
import {CdkScrollable} from '@angular/cdk/scrolling';
import {NgClass, NgFor, NgIf, TitleCasePipe} from '@angular/common';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {ModalFooterComponent} from '../../../layout/modal-footer/modal-footer.component';

@Component({
    selector: 'app-book-a-consultation',
    templateUrl: './book-a-consultation.component.html',
    styleUrls: ['./book-a-consultation.component.scss'],
    standalone: true,
    imports: [ModalHeaderComponent, CdkScrollable, MatDialogContent, FormsModule, ReactiveFormsModule, NgFor, NgClass, MatFormField, MatLabel, MatInput, NgIf, MatDialogActions, ModalFooterComponent, TitleCasePipe]
})
export class BookAConsultationComponent {
  form: FormGroup
  submitting = false
  error = false
  success = false
  channels = ['email', 'slack']

  constructor(
    public dialogRef: MatDialogRef<BookAConsultationComponent>,
    private authSvc: AuthService,
    private firestore: Firestore
  ) {
    this.form = new FormGroup({
      channel: new FormControl('email', Validators.required),
      uid: new FormControl(this.authSvc.uid),
      email: new FormControl(this.authSvc.email),
      date: new FormControl(new Date().getTime()),
      type: new FormControl('consultation'),
    })
  }

  get channel() { return this.form.get('channel') as FormControl }

  async submit() {
    this.form.markAllAsTouched()
    if (this.form.valid) {
      this.submitting = true
      try {
        await addDoc(collection(this.firestore, 'feedback'), this.form.value)
        this.success = true
      } catch (e) {
        this.error = true
      }
      this.submitting = false
    }
  }
}
