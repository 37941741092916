<app-modal-header title="Match property on..." (close)="dialogRef.close()"></app-modal-header>

<mat-dialog-content>
  <div class="text-gray-400 text-sm">
    <div class="mb-4" *ngFor="let match of data.matches">
      <div [ngSwitch]="match">
        <div *ngSwitchCase="'id'">
          <h4 class="font-bold mb-2">Match on ID</h4>
          <p>
            This means your computation value is exactly the value the platform is expecting. In other words, your
            computation will provide the final value that will be sent to the destination. This is the method that uses less requests, but it is also less human readable.
          </p>
        </div>
        <div *ngSwitchCase="'value'">
          <h4 class="font-bold mb-1">Match on value</h4>
          <p>
            The computation value is not exactly what the platform expects. This usually happens when the destination
            field is a select or multi select. In this case the value is an identifier that sometimes is not the value
            you are passing. In that situation, we will try to get that identifier for you based on your computation
            value.
          </p>
        </div>
        <div *ngSwitchCase="'email'">
          <h4 class="font-bold mb-1">Match on email</h4>
          <p>
            The computation value is not exactly what the platform expects. The platform usually expects an identifier,
            but us humans usually work with emails. In this case, we will try to get that identifier for you based on the email you are passing.
          </p>
        </div>
      </div>
    </div>
  </div>


</mat-dialog-content>

<mat-dialog-actions class="mt-auto">
  <app-modal-footer class="w-full" (onClose)="dialogRef.close()"></app-modal-footer>
</mat-dialog-actions>
