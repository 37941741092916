import {Injectable} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {AreYouSureComponent} from "../../modals/common/are-you-sure/are-you-sure.component";
import {firstValueFrom} from "rxjs";
import {centeredModal, lg, xl2} from "../../../../assets/config/modals";
import {ComputationReferenceInterface} from "../../interfaces/computation/computation-reference.interface";
import {ComputationExampleComponent} from "../../modals/computation-example/computation-example.component";
import {ReportBugComponent} from "../../modals/common/report-bug/report-bug.component";
import {SuggestComputationComponent} from "../../modals/common/suggest-computation/suggest-computation.component";
import {LimitReachedComponent} from "../../modals/common/limit-reached/limit-reached.component";
import {OutputPathInfoComponent} from "../../modals/info/output-path-info/output-path-info.component";
import {InfoAuthTokenComponent} from "../../modals/info/info-auth-token/info-auth-token.component";
import {TierInterface} from "../../interfaces/billing/tier.Interface";
import {
  ChangeTierConfirmationComponent
} from "../../modals/common/change-tier-confirmation/change-tier-confirmation.component";
import {InfoMatchValueOnComponent} from "../../modals/info/info-match-value-on/info-match-value-on.component";
import {InfoUnwrapObjectComponent} from "../../modals/info/info-unwrap-object/info-unwrap-object.component";
import {ErrorModalComponent} from "../../modals/common/error-modal/error-modal.component";
import {HowToPostDataComponent} from "../../modals/info/how-to-post-data/how-to-post-data.component";
import {
  ComputationsTimeoutDisclaimerComponent
} from "../../modals/common/computations-timeout-disclaimer/computations-timeout-disclaimer.component";
import {ModalInfoWhatIsComponent} from "../../modals/info/modal-info-what-is/modal-info-what-is.component";
import {InfoSlackChannelComponent} from "../../modals/info/info-slack-channel/info-slack-channel.component";
import {BookAConsultationComponent} from "../../modals/common/book-a-consultation/book-a-consultation.component";
import {
  InfoStandardIntegrationComponent
} from "../../modals/info/info-standard-integration/info-standard-integration.component";
import {WorkspaceInterface} from "../../interfaces/workspace.interface";
import {
  InfoAdditionalCostDetailsComponent
} from "../../modals/info/info-additional-cost-details/info-additional-cost-details.component";
import {AdditionalCostsInterface} from "../../interfaces/workspace/additional-costs.interface";

@Injectable({
  providedIn: 'root'
})
export class CommonModalsService {
  constructor(
    private dialog: MatDialog
  ) { }

  async areYouSure(extraConfirmation: boolean, section: string) {
    const modalRef = this.dialog.open(AreYouSureComponent, {
      panelClass: centeredModal,
      maxWidth: lg,
      data: { extraConfirmation, section },
    });
    const res = await firstValueFrom(modalRef.afterClosed());
    return (res) ? res : null
  }

  async viewComputationExample(computation: ComputationReferenceInterface) {
    const modalRef = this.dialog.open(ComputationExampleComponent, {
      panelClass: centeredModal,
      maxWidth: lg,
      data: { computation },
    });
    await firstValueFrom(modalRef.afterClosed());
    return
  }

  async reportBug() {
    const modalRef = this.dialog.open(ReportBugComponent, {
      panelClass: centeredModal,
      maxWidth: lg,
      data: { },
    });
    const res = await firstValueFrom(modalRef.afterClosed());
    return (res) ? res : null
  }

  async suggestComputation() {
    const modalRef = this.dialog.open(SuggestComputationComponent, {
      panelClass: centeredModal,
      maxWidth: lg,
      data: { },
    });
    const res = await firstValueFrom(modalRef.afterClosed());
    return (res) ? res : null
  }

  async limitReached() {
    const modalRef = this.dialog.open(LimitReachedComponent, {
      panelClass: centeredModal,
      maxWidth: lg,
      data: { },
    });
    const res = await firstValueFrom(modalRef.afterClosed());
    return (res) ? res : null
  }

  async outputPathInfo() {
    const modalRef = this.dialog.open(OutputPathInfoComponent, {
      panelClass: centeredModal,
      maxWidth: lg,
      data: { },
    });
    const res = await firstValueFrom(modalRef.afterClosed());
    return (res) ? res : null
  }

  async infoAuthToken(currentToken?: string) {
    const modalRef = this.dialog.open(InfoAuthTokenComponent, {
      panelClass: centeredModal,
      maxWidth: lg,
      data: {
        token: currentToken
      },
    });
    const res = await firstValueFrom(modalRef.afterClosed());
    return (res) ? res : null
  }

  async changeTierConfirmation(tier: TierInterface, mode: 'upgrade' | 'downgrade') {
    const modalRef = this.dialog.open(ChangeTierConfirmationComponent, {
      panelClass: centeredModal,
      maxWidth: lg,
      data: { tier, mode },
    });
    const res = await firstValueFrom(modalRef.afterClosed());
    return (res) ? res : null
  }

  async infoMatchValueOn(platform: string, matches: string[]) {
    const modalRef = this.dialog.open(InfoMatchValueOnComponent, {
      panelClass: centeredModal,
      maxWidth: lg,
      data: { platform, matches },
    });
    const res = await firstValueFrom(modalRef.afterClosed());
    return (res) ? res : null
  }

  async unwrapInfo() {
    const modalRef = this.dialog.open(InfoUnwrapObjectComponent, {
      panelClass: centeredModal,
      maxWidth: lg,
      data: { },
    });
    const res = await firstValueFrom(modalRef.afterClosed());
    return (res) ? res : null
  }

  async showErrorModal(error: string, status: number, modal: string, action: string, data: any) {
    const modalRef = this.dialog.open(ErrorModalComponent, {
      panelClass: centeredModal,
      maxWidth: lg,
      data: { error, status, modal, action, data },
    });
    const res = await firstValueFrom(modalRef.afterClosed());
    return (res) ? res : null
  }

  async howToPostData(section: string, endpoint: string, bearer?: string) {
    const modalRef = this.dialog.open(HowToPostDataComponent, {
      panelClass: centeredModal,
      maxWidth: lg,
      data: { section, endpoint, bearer },
    });
    const res = await firstValueFrom(modalRef.afterClosed());
    return (res) ? res : null
  }

  async computationsTimeoutInfo() {
    const modalRef = this.dialog.open(ComputationsTimeoutDisclaimerComponent, {
      panelClass: centeredModal,
      maxWidth: lg,
      data: { },
    });
    const res = await firstValueFrom(modalRef.afterClosed());
    return (res) ? res : null
  }

  async modalInfoWhatIs(element: string) {
    const modalRef = this.dialog.open(ModalInfoWhatIsComponent, {
      panelClass: centeredModal,
      maxWidth: lg,
      data: { element },
    });
    const res = await firstValueFrom(modalRef.afterClosed());
    return (res) ? res : null
  }

  async infoSlackChannel() {
    const modalRef = this.dialog.open(InfoSlackChannelComponent, {
      panelClass: centeredModal,
      maxWidth: lg,
      data: { },
    });
    const res = await firstValueFrom(modalRef.afterClosed());
    return (res) ? res : null
  }

  async bookConsultation() {
    const modalRef = this.dialog.open(BookAConsultationComponent, {
      panelClass: centeredModal,
      maxWidth: lg,
      data: { },
    });
    const res = await firstValueFrom(modalRef.afterClosed());
    return (res) ? res : null
  }

  async infoStandardIntegration(integrationType: string) {
    const modalRef = this.dialog.open(InfoStandardIntegrationComponent, {
      panelClass: centeredModal,
      maxWidth: xl2,
      data: { integration: integrationType },
    });
    const res = await firstValueFrom(modalRef.afterClosed());
    return (res) ? res : null
  }

  async infoAdditionalCostDetails(additionalCost: AdditionalCostsInterface) {
    const modalRef = this.dialog.open(InfoAdditionalCostDetailsComponent, {
      panelClass: centeredModal,
      maxWidth: lg,
      data: {
        additionalCost
      },
    });
    const res = await firstValueFrom(modalRef.afterClosed());
    return (res) ? res : null
  }
}
