import {Component, OnInit} from '@angular/core';
// import {MatDialogRef} from "@angular/material/dialog";
// import {CommonModalsService} from "../../../services/modals/shared-modals.service";
import {MatDialogActions, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {ModalHeaderComponent} from '../../../layout/modal-header/modal-header.component';
import {CdkScrollable} from '@angular/cdk/scrolling';
import {PrettyPrintJsonComponent} from '../../../components/pretty-print-json/pretty-print-json.component';
import {ModalFooterComponent} from '../../../layout/modal-footer/modal-footer.component';

@Component({
    selector: 'app-output-path-info',
    templateUrl: './output-path-info.component.html',
    styleUrls: ['./output-path-info.component.scss'],
    standalone: true,
    imports: [ModalHeaderComponent, CdkScrollable, MatDialogContent, PrettyPrintJsonComponent, MatDialogActions, ModalFooterComponent]
})
export class OutputPathInfoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<OutputPathInfoComponent>,
  ) { }

  ngOnInit(): void {
  }

}
