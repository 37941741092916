<app-modal-header title="Slack channels" (close)="dialogRef.close()"></app-modal-header>

<mat-dialog-content>
  <div class="text-gray-400 text-sm">
    <p class="mb-3">
      To successfully integrate DataMorf with your Slack channels, you need to follow two key steps:
    </p>

    <ol class="list-decimal pl-4">
      <li class="mb-3">
        <h4 class="font-bold mb-1 text-lg text-gray-700">Add the DataMorf app to your channel</h4>
        <ul class="pl-1 list-disc">
          <li>Navigate to the desired Slack channel/group/conversation.</li>
          <li>Add the DataMorf app in the integrations tab:
            <div class="bg-gray-800 rounded p-3 text-white monospace -ml-5">
              Channel details > Integrations > Add app > Select DataMorf
            </div>
          </li>
        </ul>
      </li>
      <li class="mb-3">
        <h4 class="font-bold mb-1 text-lg text-gray-700">Get the channel ID</h4>
        <ul class="pl-1 list-disc">
          <li>Open the details of the channel/group/conversation.</li>
          <li>At the bottom of the "About" tab you will find the channel ID.</li>
        </ul>
      </li>
    </ol>

    <p class="mt-4">
      Here you have a screenshot for better understanding of the whole process:
    </p>
    <img src="./assets/img/info/slack-channel-id.png" alt="Slack channel id location" class="rounded-lg shadow w-full">

  </div>


</mat-dialog-content>

<mat-dialog-actions class="mt-auto">
  <app-modal-footer class="w-full" (onClose)="dialogRef.close()"></app-modal-footer>
</mat-dialog-actions>
