import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {ModalHeaderComponent} from '../../../layout/modal-header/modal-header.component';
import {CdkScrollable} from '@angular/cdk/scrolling';
import {NgFor, NgSwitch, NgSwitchCase} from '@angular/common';
import {ModalFooterComponent} from '../../../layout/modal-footer/modal-footer.component';

@Component({
    selector: 'app-info-match-value-on',
    templateUrl: './info-match-value-on.component.html',
    styleUrls: ['./info-match-value-on.component.scss'],
    standalone: true,
    imports: [ModalHeaderComponent, CdkScrollable, MatDialogContent, NgFor, NgSwitch, NgSwitchCase, MatDialogActions, ModalFooterComponent]
})
export class InfoMatchValueOnComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<InfoMatchValueOnComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      platform: string,
      matches: string[]
    },
  ) { }

  ngOnInit(): void {
  }

}
