<section class="h-full flex flex-col ">
  <app-modal-header title="Are you sure?" (close)="dialogRef.close()"></app-modal-header>

  <mat-dialog-content>

    <div [ngSwitch]="data.section">
      <p class="mb-2" *ngSwitchCase="'workspace-stuff'">
        This action can affect other elements from your workspace.
      </p>
      <ng-container *ngSwitchCase="'change-auth-token'" class="mb-2">
        <p class="mb-2">
          <i class="fas fa-exclamation-triangle text-red-700"></i>
          Please be aware that this action will invalidate the existing token immediately.
        </p>
        <p class="mb-2">
          Any systems, applications, or integrations using the current token will need to be updated with the new token to
          continue accepting requests. Failure to update the token in all relevant places may result in rejected
          connections and a disruption in data transmission.
        </p>
        <p class="mb-2">
          Please ensure that you are ready to make this change and have access to all necessary areas to update the token
          promptly.
        </p>
      </ng-container>
      <p class="mb-2" *ngSwitchCase="'delete-computations-group'">
        You will also delete all computations in this group.
      </p>
      <p class="mb-2" *ngSwitchCase="'delete-workflow'">
        You are about to delete this workflow and all its related data.
      </p>
      <p class="mb-2" *ngSwitchCase="'delete-extraction'">
        You are about to delete this data extraction and all its related data.
      </p>
      <p class="mb-2" *ngSwitchCase="'stop-extraction'">
        You are about to interrupt this data extraction execution. Stopping can take a few minutes to be effective.
      </p>
      <p class="mb-2" *ngSwitchCase="'run-extraction'">
        This action will automatically activate and save your current extraction config and execute it immediately. You can cancel the run anytime from the history tab.
      </p>

      <p class="mb-2" *ngSwitchDefault>
        Please confirm your action.
      </p>

    </div>

    <div *ngIf="data.extraConfirmation">
      <p class="mb-2">
        As a safety measure, please write <code>DELETE</code> in the box below:
      </p>
      <mat-form-field class="w-full mb-3" appearance="outline">
        <mat-label>Delete</mat-label>
        <input matInput placeholder="DELETE" [(ngModel)]="input">
      </mat-form-field>
    </div>


  </mat-dialog-content>

  <mat-dialog-actions class="mt-auto">
    <app-modal-footer class="w-full" [confirm]="true" (onConfirm)="confirm()"
                      (onClose)="dialogRef.close()"></app-modal-footer>
  </mat-dialog-actions>
</section>
