<app-modal-header title="What is..." (close)="dialogRef.close()"></app-modal-header>

<mat-dialog-content>
  <ng-container [ngSwitch]="data.element">
    <div *ngSwitchCase="'playground'">
      <h2 class="mb-2 text-xl font-bold">Workflow playground</h2>
      <div class="text-gray-400 text-sm">
        <p class="mb-2">
          Here you can experiment with your computations. Load and visualize up to 15 items at once and tweak the computations
          settings to get your desired result.
        </p>
        <p class="mb-2">
          Playground runs the data sources and computations,
          but it does not trigger any destination, so you are free to tweak your settings without sending any data to downstream tools.
        </p>
      </div>
    </div>
  </ng-container>



</mat-dialog-content>

<mat-dialog-actions class="mt-auto">
  <app-modal-footer class="w-full" (onClose)="dialogRef.close()"></app-modal-footer>
</mat-dialog-actions>
