import {Component, OnInit} from '@angular/core';
import {MatDialogActions, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {ModalHeaderComponent} from '../../../layout/modal-header/modal-header.component';
import {CdkScrollable} from '@angular/cdk/scrolling';
import {PrettyPrintJsonComponent} from '../../../components/pretty-print-json/pretty-print-json.component';
import {ModalFooterComponent} from '../../../layout/modal-footer/modal-footer.component';

@Component({
    selector: 'app-info-unwrap-object',
    templateUrl: './info-unwrap-object.component.html',
    styleUrls: ['./info-unwrap-object.component.scss'],
    standalone: true,
    imports: [ModalHeaderComponent, CdkScrollable, MatDialogContent, PrettyPrintJsonComponent, MatDialogActions, ModalFooterComponent]
})
export class InfoUnwrapObjectComponent implements OnInit {
  objectBefore = {
    top_level_field: {
      name: 'DataMorf',
      email: 'info@datamorf.io',
      type: 'company'
    }
  }
  objectAfter = {
    name: 'DataMorf',
    email: 'info@datamorf.io',
    type: 'company'
  }
  constructor(
    public dialogRef: MatDialogRef<InfoUnwrapObjectComponent>,
  ) { }

  ngOnInit(): void {
  }

}
