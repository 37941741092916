<section class="h-full flex flex-col">
  <app-modal-header title="Suggest a new computation" (close)="dialogRef.close()"></app-modal-header>

  <mat-dialog-content>
    <form [formGroup]="suggestionForm">
      <p class="mb-3">
        We value your input and want to hear your ideas for new computations that you would like to see here.
        Please let us know if you have any specific need and we will do our best to implement it for you.
      </p>

      <mat-form-field class="w-full" appearance="outline">
        <mat-label>Explain your need</mat-label>
        <textarea matInput formControlName="message" rows="5"></textarea>
      </mat-form-field>

    </form>

    <p class="p-6 bg-accent-50 rounded-xl text-gray-800" *ngIf="error">
      <i class="fas fa-exclamation-triangle mr-1 text-red-600"></i>
      So ironic... The "report an error" function is currently unavailable. We
      apologize for the inconvenience and promise to get it back up and running ASAP.
    </p>
    <p class="p-6 bg-secondary-50 rounded-xl text-gray-800" *ngIf="success">
      <i class="fas fa-check-circle mr-1 text-green-600"></i>
      Feedback sent! We will action it ASAP. Thank you!
    </p>


  </mat-dialog-content>

  <mat-dialog-actions class="mt-auto">
    <app-modal-footer class="w-full" (onClose)="dialogRef.close()" [send]="!error && !success" (onSend)="send()"
                      [processing]="submitting"></app-modal-footer>
  </mat-dialog-actions>

</section>
