<app-modal-header title="Additional cost" (close)="dialogRef.close()"></app-modal-header>

<mat-dialog-content>
  <div class="text-gray-400 text-sm">
    <p class="mb-3">
      Additional costs may apply to your additionalCost due to the use of third-party services (e.g., GPT, data enrichment, etc.).
    </p>
    <p class="mb-3">
      Any available balance in your account will be applied first to cover these charges.
    </p>

    <table class="table-auto w-full">
      <thead>
        <tr>
          <th>Feature</th>
          <th>Total cost</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="data.additionalCost['web-unlock']">
          <td>Web unlocker</td>
          <td>{{data.additionalCost['web-unlock'] | currency:'USD'}}</td>
        </tr>
        <tr *ngIf="data.additionalCost['linkedin-scraper']">
          <td>LinkedIn scraper</td>
          <td>{{data.additionalCost["linkedin-scraper"] | currency:'USD'}}</td>
        </tr>
        <tr *ngIf="data.additionalCost['instagram-scraper']">
          <td>Instagram scraper</td>
          <td>{{data.additionalCost["instagram-scraper"] | currency:'USD'}}</td>
        </tr>
        <tr *ngIf="data.additionalCost.gpt">
          <td>GPT AI</td>
          <td>{{data.additionalCost.gpt | currency:'USD'}}</td>
        </tr>
        <tr *ngIf="data.additionalCost.claude">
          <td>Claude AI</td>
          <td>{{data.additionalCost.claude | currency:'USD'}}</td>
        </tr>
        <tr *ngIf="data.additionalCost.waterfallContact">
          <td>Waterfall contact enrichment</td>
          <td>{{data.additionalCost.waterfallContact | currency:'USD'}}</td>
        </tr>
        <tr *ngIf="data.additionalCost.waterfallCompany">
          <td>Waterfall company enrichment</td>
          <td>{{data.additionalCost.waterfallCompany | currency:'USD'}}</td>
        </tr>
        <tr *ngIf="data.additionalCost.waterfallPhone">
          <td>Waterfall phone enrichment</td>
          <td>{{data.additionalCost.waterfallPhone | currency:'USD'}}</td>
        </tr>
        <tr *ngIf="data.additionalCost.waterfallEmailVerification">
          <td>Waterfall email verification</td>
          <td>{{data.additionalCost.waterfallEmailVerification | currency:'USD'}}</td>
        </tr>
        <tr *ngIf="data.additionalCost.zerobounce">
          <td>ZeroBounce email verification</td>
          <td>{{data.additionalCost.zerobounce | currency:'USD'}}</td>
        </tr>
        <tr *ngIf="data.additionalCost['datamorf-extra-run']">
          <td>Additional workflow runs</td>
          <td>{{data.additionalCost["datamorf-extra-run"] | currency:'USD'}}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>Total</td>
          <td>{{data.additionalCost.total | currency:'USD'}}</td>
        </tr>
      </tfoot>
    </table>
  </div>


</mat-dialog-content>

<mat-dialog-actions class="mt-auto">
  <app-modal-footer class="w-full" (onClose)="dialogRef.close()"></app-modal-footer>
</mat-dialog-actions>
