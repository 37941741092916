import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {ComputationReferenceInterface} from "../../interfaces/computation/computation-reference.interface";
import {HelperService} from "../../services/helper.service";
import {ModalHeaderComponent} from '../../layout/modal-header/modal-header.component';
import {CdkScrollable} from '@angular/cdk/scrolling';
import {NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault} from '@angular/common';
import {PrettyPrintJsonComponent} from '../../components/pretty-print-json/pretty-print-json.component';
import {ModalFooterComponent} from '../../layout/modal-footer/modal-footer.component';

@Component({
    selector: 'app-computation-example',
    templateUrl: './computation-example.component.html',
    styleUrls: ['./computation-example.component.scss'],
    standalone: true,
    imports: [ModalHeaderComponent, CdkScrollable, MatDialogContent, NgSwitch, NgSwitchCase, PrettyPrintJsonComponent, NgIf, NgSwitchDefault, MatDialogActions, ModalFooterComponent]
})
export class ComputationExampleComponent implements OnInit {
  itemInList = [
    {
      datapoint: 'Image',
      url: 'Whatever image url'
    }, {
      datapoint: 'linkedin',
      url: 'linkedin.com/in/datamorf'
    }, {
      datapoint: 'website',
      url: 'datamorf.io'
    }
  ]
  list = [
    {
      listId: 1574,
      timestamp: 16550,
      active: true
    },
    {
      listId: 504,
      timestamp: 1300,
      active: true
    },
    {
      listId: 1440,
      timestamp: 17400,
      active: true
    },
    {
      listId: 354,
      timestamp: 14550,
      active: true
    }
  ]
  object = {
    user: {
      name: {
        first: 'John',
        last: 'Doe'
      },
      email: 'john@datamorf.io'
    }
  }
  list2 = ['Facebook', 'Amazon', 'Apple', 'Netflix', 'Google']
  executionId = this.helperSvc.generateId(25)
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      computation: ComputationReferenceInterface,
    },
    public dialogRef: MatDialogRef<ComputationExampleComponent>,
    private helperSvc: HelperService
  ) { }

  ngOnInit(): void {
  }

}
