import {Component, OnInit} from '@angular/core';
import {MatDialogActions, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {AuthService} from "../../../services/auth.service";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {addDoc, collection, Firestore} from "@angular/fire/firestore";
import {ModalHeaderComponent} from '../../../layout/modal-header/modal-header.component';
import {CdkScrollable} from '@angular/cdk/scrolling';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {NgIf} from '@angular/common';
import {ModalFooterComponent} from '../../../layout/modal-footer/modal-footer.component';

@Component({
    selector: 'app-suggest-computation',
    templateUrl: './suggest-computation.component.html',
    styleUrls: ['./suggest-computation.component.scss'],
    standalone: true,
    imports: [ModalHeaderComponent, CdkScrollable, MatDialogContent, FormsModule, ReactiveFormsModule, MatFormField, MatLabel, MatInput, NgIf, MatDialogActions, ModalFooterComponent]
})
export class SuggestComputationComponent implements OnInit {
  suggestionForm: FormGroup
  submitting = false
  error = false
  success = false
  constructor(
    public dialogRef: MatDialogRef<SuggestComputationComponent>,
    private authSvc: AuthService,
    private firestore: Firestore
  ) {
    this.suggestionForm = new FormGroup<any>({
      subject: new FormControl('Computation suggestion', Validators.required),
      message: new FormControl('', [Validators.required, Validators.maxLength(2000)]),
      path: new FormControl(location.pathname),
      uid: new FormControl(this.authSvc.uid),
      date: new FormControl(new Date().getTime()),
      type: new FormControl('computation-suggestion')
    })
  }

  ngOnInit(): void {
  }

  async send() {
    this.suggestionForm.markAllAsTouched()
    if (this.suggestionForm.valid) {
      this.submitting = true
      try {
        await addDoc(collection(this.firestore, 'feedback'), this.suggestionForm.value)
        this.success = true
      } catch (e) {
        this.error = true
      }
      this.submitting = false
    }
  }

}
