import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions } from "@angular/material/dialog";
import { ModalHeaderComponent } from '../../../layout/modal-header/modal-header.component';
import {NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault} from '@angular/common';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { ModalFooterComponent } from '../../../layout/modal-footer/modal-footer.component';

@Component({
    selector: 'app-are-you-sure',
    templateUrl: './are-you-sure.component.html',
    styleUrls: ['./are-you-sure.component.scss'],
    standalone: true,
  imports: [ModalHeaderComponent, MatDialogContent, NgIf, MatFormField, MatLabel, MatInput, FormsModule, MatDialogActions, ModalFooterComponent, NgSwitch, NgSwitchCase, NgSwitchDefault]
})
export class AreYouSureComponent implements OnInit {
  input = ''
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      extraConfirmation: boolean,
      section: string
    },
    public dialogRef: MatDialogRef<AreYouSureComponent>
  ) {
  }

  ngOnInit(): void {
  }

  confirm() {
    if ((this.data.extraConfirmation && this.input.toUpperCase() === 'DELETE') || !this.data.extraConfirmation) {
      this.dialogRef.close(true)
    }
  }

}
