import {Component, OnInit} from '@angular/core';
import { MatDialogRef, MatDialogContent, MatDialogActions } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {AuthService} from "../../../services/auth.service";
import {addDoc, collection, doc, Firestore} from "@angular/fire/firestore";
import { ModalHeaderComponent } from '../../../layout/modal-header/modal-header.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { NgIf } from '@angular/common';
import { ModalFooterComponent } from '../../../layout/modal-footer/modal-footer.component';

@Component({
    selector: 'app-report-bug',
    templateUrl: './report-bug.component.html',
    styleUrls: ['./report-bug.component.scss'],
    standalone: true,
    imports: [ModalHeaderComponent, CdkScrollable, MatDialogContent, FormsModule, ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatSelect, MatOption, NgIf, MatDialogActions, ModalFooterComponent]
})
export class ReportBugComponent implements OnInit {
  reportForm: FormGroup
  submitting = false
  error = false
  success = false
  constructor(
    public dialogRef: MatDialogRef<ReportBugComponent>,
    private authSvc: AuthService,
    private firestore: Firestore
  ) {
    this.reportForm = new FormGroup<any>({
      subject: new FormControl('', Validators.required),
      message: new FormControl('', [Validators.required, Validators.maxLength(1000)]),
      path: new FormControl(location.pathname),
      email: new FormControl(this.authSvc.email),
      uid: new FormControl(this.authSvc.uid),
      date: new FormControl(new Date().getTime()),
      type: new FormControl('help')
    })
  }

  ngOnInit(): void {
  }

  async report() {
    this.reportForm.markAllAsTouched()
    if (this.reportForm.valid) {
      this.submitting = true
      try {
        await addDoc(collection(this.firestore, 'feedback'), this.reportForm.value)
        this.success = true
      } catch (e) {
        this.error = true
      }
      this.submitting = false
    }
  }
}
