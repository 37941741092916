import {Component, Inject, OnInit} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {HelperService} from "../../../services/helper.service";
import {ModalHeaderComponent} from '../../../layout/modal-header/modal-header.component';
import {NgIf, NgSwitch, NgSwitchCase} from '@angular/common';
import {ModalFooterComponent} from '../../../layout/modal-footer/modal-footer.component';

@Component({
    selector: 'app-how-to-post-data',
    templateUrl: './how-to-post-data.component.html',
    styleUrls: ['./how-to-post-data.component.scss'],
    standalone: true,
    imports: [ModalHeaderComponent, MatDialogContent, NgSwitch, NgSwitchCase, NgIf, MatDialogActions, ModalFooterComponent]
})
export class HowToPostDataComponent implements OnInit {
  url: string
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      endpoint: string,
      bearer?: string
      section: string
    },
    public dialogRef: MatDialogRef<HowToPostDataComponent>,
    public helperSvc: HelperService
  ) { }

  ngOnInit(): void {
    this.url = environment.core + 'v2/' + this.data.endpoint
  }

}
