<app-modal-header title="Unwrap object" (close)="dialogRef.close()"></app-modal-header>

<mat-dialog-content>
  <div class="text-gray-400 text-sm">
    When you unwrap a payload or object, the core or essential content remains unchanged, but you strip away the outer
    layer or container that holds it. Let's show it with an example:
    <div class="my-2">
      <strong>Payload before unwrapping</strong>
      <app-pretty-print-json [object]="objectBefore"></app-pretty-print-json>
    </div>
    <div class="my-2">
      <strong>Payload after unwrapping</strong>
      <app-pretty-print-json [object]="objectAfter"></app-pretty-print-json>
    </div>
  </div>


</mat-dialog-content>

<mat-dialog-actions class="mt-auto">
  <app-modal-footer class="w-full" (onClose)="dialogRef.close()"></app-modal-footer>
</mat-dialog-actions>
