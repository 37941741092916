<app-modal-header title="Auth token" (close)="dialogRef.close()"></app-modal-header>

<mat-dialog-content>
  <div class="text-gray-400 text-sm">
    <p class="mb-3">
      The authentication feature in Datamorf provides an additional layer of security for your data endpoints. By
      activating this feature, the endpoints require an 'authorization' header with a specific 'Bearer token' that
      matches the one assigned to your account.
    </p>
    <p class="mb-3">
      Any data payload sent without this matching token, or with an
      incorrect token, will be rejected. This ensures that only authorized clients can send data to these endpoints,
      preventing unauthorized access or data manipulation.
    </p>
    <p class="mb-3">
      Here's an example of how you can send a JSON payload to an endpoint:
    </p>

    <div class="bg-gray-800 rounded p-3 text-white monospace break-words">
      curl <span class="text-pink-500">-X</span> POST
      <br>
      <span class="text-pink-500 mr-1">-H</span> <span class="text-amber-400">"Content-Type: application/json"</span>
      <br>
      <span class="text-pink-500 mr-1">-H</span> <span class="text-amber-400">"Authorization: Bearer {{data.token || 'YOUR_ACCESS_TOKEN'}}"</span>
      <br>
      <span class="text-pink-500 mr-1">-d</span> <span class="text-amber-400">YOUR_PAYLOAD</span>
      <br>
      https://your-datamorf-trigger-endpoint
    </div>

<!--    <code>-->
<!--      curl -X POST "https://your-datamorf-trigger-endpoint"<br>-->
<!--      -H "Content-Type: application/json"<br>-->
<!--      -H "Authorization: Bearer {{data.token || 'YOUR_ACCESS_TOKEN'}}"<br>-->
<!--      -d YOUR_JSON_PAYLOAD-->
<!--    </code>-->
  </div>


</mat-dialog-content>

<mat-dialog-actions class="mt-auto">
  <app-modal-footer class="w-full" (onClose)="dialogRef.close()"></app-modal-footer>
</mat-dialog-actions>
